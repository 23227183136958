<template>
  <base-modal :modal.sync="modal" @changeModal="e => $emit('change-modal', e)">
    <div class="modal__content">
      <div class="modal__header">
        <h2 class="modal__title">
          Materiais Recicláveis.
        </h2>

        <p class="modal__description">
          Saiba quais são os materiais recicláveis e não-recicláveis de cada
          categoria.
        </p>
      </div>

      <div class="full-width" v-if="loading">Buscando dados...</div>
      <ul v-else class="modal__cats--list">
        <li v-for="item in items" :key="item.id" class="modal__cats--item" data-modal="modal-recycled-materials" >
          <div class="item-recycled-title" @click="toggle(item)" :class="{ opened: item.opened }">
            <span>{{ item.name }}</span>
            <i class="fa fa-chevron-down"></i>
          </div>
          <ul v-if="item.opened" class="item-recycled-content">
            <li v-for="(recycle, index) in item.recyclable_items" :key="index">
              {{ recycle }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </base-modal>
</template>

<script>
export default {
  props: {
    modal: Boolean
  },

  data: () => ({
    loading: true,
    items: []
  }),

  methods: {
    toggle ({ id, opened }) {
      this.items = this.items.map(item => {
        return { ...item, opened: item.id === id && !opened }
      })
    }
  },

  components: {
    baseModal: () => import('@/components/modal')
  },
  async mounted () {
    this.loading = true
    const response = await this.$http.get('items?page=1&limit=200')
    this.items = response.data.data.map(item => {
      return { ...item, opened: false }
    })
    this.loading = false
  }
}
</script>

<style scoped>
.modal-recycled-materials {
  margin-bottom: 1rem;
}

.item-recycled-title {
  display: block;
  position: relative;
  margin-bottom: 0.5rem;
}

.item-recycled-title i {
  color: #05b446;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(360deg);
  transition: 0.3s;
}

.item-recycled-title.opened {
  color: #05b446;
}

.item-recycled-title.opened i {
  transform: translateY(-50%) rotate(180deg);
}

.item-recycled-content li {
  margin: 0;
  text-transform: capitalize;
  cursor: default;
}

.item-recycled-content li:last-child {
  margin-bottom: 1.5rem;
}
</style>
